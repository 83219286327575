import React from "react";

import Layout from "components/layout";
import { useUser } from "context/userContext";
import Loader from "components/loader";
import Tutoring from "components/Tutoring";
import TutoringSub from "components/TutoringSub";

const TutoringStatus = ({ profile }) => {
  const { tutoring, firstName, lastName } = profile;
  return (
    <div>
      <h3>
        لقد تم تسجيل التليمذ (ة):
        {firstName} {lastName}
        بنجاح في الدروس الخصوصية لمادة الفيزياء
      </h3>
      <h3>اليوم</h3>
      <p>{tutoring.day}</p>
      <h3>الوقت</h3>
      <p>{tutoring.time}</p>
    </div>
  );
};
const tutoringSessions = ({ location }) => {
  const {
    userDetail: { isLoggedIn, isLoading, profile },
  } = useUser();
  const isTutored = () => {
    return profile?.tutoring?.time !== "";
  };
  if (isLoading) {
    return (
      <Layout title="من فضلك انتظر حتى يتم التحميل ">
        <Loader />
      </Layout>
    );
  }
  //ToDo: add a youtube video
  return (
    <Layout title={"دروس خصوصية"}>
      {isLoggedIn ? (
        isTutored() ? (
          <TutoringStatus profile={profile} />
        ) : (
          <TutoringSub profile={profile} />
        )
      ) : (
        <Tutoring location={location} />
      )}
    </Layout>
  );
};

export default tutoringSessions;
