import React, { useEffect } from "react";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ls } from "utility/helper";

const Tutoring = ({ location }) => {
  useEffect(() => {
    ls("prevLink", location.pathname);
  }, []);

  return (
    <div>
      <StaticImage src="../images/ads_1.91x1_tuto_A1.png" alt="ads" />
      <h3>التسجيلات متواصلة للمستوى الثالثة ثانوي حتى 4أكتوبر 2024</h3>
      <p>
        بن أحمد, أستاذ العلوم الفيزيائية في الطور الثانوي, أكثر من
        <b>10 سنوات خبرة</b>
      </p>
      <p>
        سعر الدروس شهريا
        <b> 2500 دينار جزائري </b>
      </p>
      <p>عدد التلاميذ في الفوج لا يتعدى 20</p>
      <p>
        <a href="https://maps.app.goo.gl/tkUny9MzHjRAZJYr7">
          العنوان:وهران حي كاسطور الطابق الثالث
        </a>
      </p>
      <Link className="button primary" to="/Signin">
        سجل الآن
      </Link>
    </div>
  );
};

export default Tutoring;
