import React, { useState } from "react";
import Tutoring from "./molecules/Modals/CompleteRegistration/Tutoring";
import PersonalInfos from "./molecules/Modals/CompleteRegistration/PersonalInfos";
import Summary from "./molecules/Modals/CompleteRegistration/Summary";
import { navigate } from "gatsby";
import { getDivision, getLevel } from "utility/helper";

function TutoringSub({ profile }) {
  const [formState, setFormState] = useState("tutoring");
  const [values, setValues] = useState({
    level: [getLevel(profile.level), profile.level],
    division: [getDivision(profile.level, profile.division), profile.division],
    phase: ["الثانوي", "3"],
    userType: profile?.userType,
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    phone: profile?.phone,
    day: "",
    time: "",
  });

  const isAvailable = () => {
    return (
      profile?.phase === 3 &&
      profile?.level === 3 &&
      profile?.userType === "student"
    );
  };
  return isAvailable() ? (
    <div>
      {formState === "tutoring" && (
        <Tutoring
          values={values}
          setValues={setValues}
          setForm={setFormState}
        />
      )}
      {formState === "info" && (
        <PersonalInfos
          values={values}
          setFormState={setFormState}
          setValues={setValues}
        />
      )}

      {formState === "signup" && (
        <Summary
          values={values}
          id={profile?.id}
          navigate={() => navigate("/دروس-خصوصية")}
        />
      )}
    </div>
  ) : (
    <h2>الدروس الخصوصية ليست متوفرة</h2>
  );
}

export default TutoringSub;
